<template>
  <img
    :src="`${runtimeConfig.public.carrierAssetsBaseUrl}/${carriers.getLogo(logoKey, planYear)}`"
    :alt="`${logoAlt} logo.`"
    width="425"
    height="100"
    :class="[logoClass, logoKey]"
  />
</template>

<script lang="ts" setup>
  import { CarrierStore } from '~/stores/carriers'

  const props = defineProps({
    logoKey: {
      type: String,
      required: true
    },
    planYear: {
      type: [String, Number],
      required: true
    },
    logoAlt: {
      type: String,
      default: 'Carrier'
    }
  })

  const carriers = CarrierStore.use()
  const runtimeConfig = useRuntimeConfig()

  const width = ref(0)
  const height = ref(0)

  onMounted(() => {
    let img = new Image()
    img.src = `${runtimeConfig.public.carrierAssetsBaseUrl}/${carriers.getLogo(
      props.logoKey,
      props.planYear
    )}`

    img.onload = () => {
      width.value = img.width
      height.value = img.height
    }
  })
  const logoClass = computed(() => {
    if (width.value > height.value * 4) {
      return 'plan-logo-long'
    } else {
      return 'plan-logo-short'
    }
  })
</script>

<style scoped>
  .plan-logo-long,
  .plan-logo-short {
    @apply object-contain object-left;
  }
  .plan-logo-long {
    @apply h-[50px];
  }
  .plan-logo-short {
    @apply mb-1 max-h-[80px] w-full max-w-[200px];
  }

  .hcsc-bcbs-il,
  .uhc,
  .zing {
    @apply mb-2;
  }
  .ibx {
    @apply md:mb-1;
  }

  .anthem-bcbs {
    @apply h-[45px];
  }
</style>
